@import "../../../styles/modules";

.spiderweb{
  position: absolute;
  overflow: hidden;
  top: -10vh;
  left: -10vh;
  width: 120vw;
  height: 120vh;
  z-index: 0;
}