@import "../../styles/modules";

.homepage {
  position: relative;

  &__section {
    position: relative;

    &--first {
      @extend .homepage__section;
      z-index: 110;
    }
  }

  &__image {
    transform: scale(1.5) translateY(10%);
    cursor: pointer;
  }

  &__image--Wrapper {
    border-radius: 100%;
    overflow: hidden;
    box-shadow: 3px 3px 5px $dark;
  }
}

@keyframes iconAppearance {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
