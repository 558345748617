@import "../../styles/modules";

.footer {
  position: relative;
  background-color: $dark;
  color: $white;
  padding-block: $spacer-5;
  z-index: 120;
  background: url(../../public/images/homepage/contact_background_bottom.png)
    no-repeat right bottom $dark;

  &__item {
    display: flex;
    align-items: center;
    text-align: left;
    color: $white;
  }

  &__item-textation {
    @extend .footer__item;
    padding-block: $spacer-3;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: start;
    }
  }

  &__item-icon {
    @extend.footer__item;
    flex-direction: column;
    padding: 1rem 0;
  }

  &__item-contact {
    @extend.footer__item;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: end;
    }
  }

  &__line {
    height: 0;
    width: 85vw;
    border: solid $light 0.5px;
  }
}
