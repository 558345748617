@import "../../../styles/modules";

.contact {
  position: relative;
  background-color: $dark;
  color: $white;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-lg;
  padding-block: $spacer-10;
  z-index: 120;

  &--backgroundImage {
    @extend .contact;
    background: url(../../../public/images/homepage/contact_background_left.png)
        no-repeat,
      url(../../../public/images/homepage/contact_background_right.png)
        no-repeat right top $dark;
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
  }

  &__content {
    max-width: 700px;
    text-align: center;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url("../../../public/icons/ticks.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &__image--Wrapper {
    border-radius: 100%;
    overflow: hidden;
  }

  &__image {
    transform: scale(1.3);

    &--business {
      transform: scale(1.1) translateY(5%);
    }
  }
}
