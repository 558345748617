@import "../../../styles/modules";

.navigation {
  position: relative;
  width: 100%;
  z-index: 200;

  &__hamburger {
    border: none;
    box-shadow: none !important;
  }

  &--matte-glass{
    @extend .navigation;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.5px);
    -webkit-backdrop-filter: blur(5.5px);
    transition: background-color 0.5s ease-in-out;
  }

  &--gray-bg{
    background-color: $dark;
    transition: background-color 0.5s ease-in-out;
  }

  &__logo{
    position: relative;
    width: 30vw;
    margin-inline-end: 1rem;
    padding-inline-end: 0;

    @include media-breakpoint-up(sm){
      padding-inline-end: 2rem;
    }
  }
}
