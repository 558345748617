@import "../../../styles/modules";

.claims {
  background: url(../../../public/images/homepage/claims_background.png) $white;
  background-repeat: no-repeat;

  &--dark {
    background: url(../../../public/images/homepage/claims_background_dark.png)
      $dark no-repeat center;
    background-size: cover;
  }

  &__container {
    display: flex;
    align-items: center;
    height: auto;
    color: $black;

    @include media-breakpoint-up(lg) {
      height: 100vh;
      padding: 0;
    }

    &--dark {
      @extend .claims__container;
      color: $white;
      b {
        color: $primary;
      }
    }
  }

  &__row {
    height: 100%;
  }

  &__column {
    height: 100%;
    color: $dark;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    margin-block: 2rem;
    row-gap: $spacer-2;

    @include media-breakpoint-up(lg) {
      width: 45%;
      row-gap: $spacer-7;
    }

    &--image {
      @extend .claims__column;
      height: 100%;
      padding-inline: $spacer-5;

      @include media-breakpoint-up(lg) {
        height: 600px;
        width: 50%;
        padding: 0;
      }

      img {
        width: auto;
        border-radius: 1rem;
        object-fit: cover;
        z-index: 10;

        @include media-breakpoint-up(lg) {
          width: 689px;
        }
      }
    }
  }
  &__number {
    font-size: 3rem;
    font-weight: $font-weight-bold;
    width: auto;
    flex: none;
    padding: 0;

    &--dark {
      @extend .claims__number;
      color: $primary;
    }
  }

  &__title {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;

    &--dark {
      @extend .claims__title;
      color: $primary;
    }
  }

  &__textation {
    font-size: $font-size-lg;
    font-weight: $font-weight-light;
    padding-right: $spacer-6;

    &--dark {
      @extend .claims__textation;
      color: $white;
    }
  }

  &__item {
    height: auto;
  }
}
