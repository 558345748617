@import "../../../styles/modules";

.header {
  background-color: $primary;
  height: 100vh;
  display: flex;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-block: $spacer-10 $spacer-5;
    height: 65%;
    width: 100%;
    
    @include media-breakpoint-up(md) {
      width: 80%;
    }
  }
}
