@import "../../../styles/modules";

$border-radius-half: 0.5rem;

.language-switch {
  display: flex;
  height: fit-content;

  &__item {
    position: relative;
    height: 2rem;
    padding: 0 $spacer-2;
    border: none;
    font-size: $font-size-sm;
    font-weight: $font-weight-bold;
    color: $white;
    background-color: transparent;

    &--pill-inactive {
      color: $white;
      background-color: $dark;

      &--desktop{
        @extend .language-switch__item--pill-inactive;
        color: $black;
        background-color: $white;
      }

      &:first-of-type {
        border-radius: $border-radius-half;
      }

      &:last-of-type {
        border-radius: $border-radius-half;
      }
    }

    &--pill-active {
      background-color: $primary;
      color: $black;

      &--homepage{
        @extend .language-switch__item--pill-active;
        color: $white;
        background-color: $dark;
      }

      &:first-of-type {
        border-radius: $border-radius-half;
      }

      &:last-of-type {
        border-radius: $border-radius-half;
      }
    }
  }
}
