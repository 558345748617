@import "../../../styles/modules";

.contact-form {
  &__form-input {
    border-radius: $border-radius-md;

    &--file {
      @extend .contact-form__form-input;
      display: flex;
      position: relative;
      cursor: pointer;
      &:hover {
        filter: brightness(95%);
      }
    }
  }

  &__file-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 151.5px;
    background-color: rgb(234, 236, 240);
    border-radius: $border-radius-md 0 0 $border-radius-md;
    transition: filter 0.3s;
  }

  &__file-delete-button {
    position: absolute;
    right: 1rem;
  }

  &__text-area {
    resize: none;
    border-radius: $border-radius-md;
  }

  &__button {
    width: 100%;
    background-color: $primary;
    border-radius: $border-radius-md;
  }
}
