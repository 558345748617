@import "../../../styles/modules";

.our-clients {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  margin-block: 0;
  background: url(../../../public/images/homepage/claims_background.png) $white no-repeat right;

  @include media-breakpoint-up(sm) {
    margin-block: 8rem;
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
    margin: 0;
  }

  &__container {
    text-align: center;
    padding-block: 4rem;
  }

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    filter: gray;
    -webkit-filter: grayscale(100%);
    opacity: 70%;
    transition: filter 0.3s, opacity 0.6s;

    &:hover {
      filter: none;
      -webkit-filter: grayscale(0%);
      opacity: 100%;
    }

    svg {
      width: 130px;
      align-self: center;
    }

    &--colored {
      filter: none;
      -webkit-filter: grayscale(0%);
      opacity: 100%;
    }
  }

  &__button {
    border-radius: $border-radius-md;
    margin: $spacer-3;
  }
}
