@import "../../../styles/modules";

.tooltip {
  background-color: $dark !important;
  border-radius: 24px !important;
  padding-block: 1rem !important;
  max-width: 14rem;
  min-width: 12rem;
  width: auto;
  max-height: 22rem;
  height: auto;
  z-index: 200;

  @include media-breakpoint-up(sm) {
    max-width: 20rem;
  }

  @include media-breakpoint-up(md) {
    max-width: 36rem;
  }

  --rt-opacity: 100%;

  &__container {
    overflow-y: auto;
    max-height: 20rem;
    position: relative;

    &::-webkit-scrollbar {
      width: 0.5vw;
    }

    &::-webkit-scrollbar-track {
      background-color: $dark;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($light, 0.3);
      border-radius: $border-radius;
    }

    &--arrow {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      border-width: 8px 6px 0;
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      z-index: 1;
    }
  }

  &__title {
    text-align: center;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    padding-block: 0.5rem;
  }

  &__position {
    text-align: center;
    font-size: $font-size-base;
  }

  &__message {
    text-align: center;
    font-size: $font-size-base;
    padding-inline: 1rem;
    padding-block: 0.5rem;
  }

  &__signature {
    display: flex;
    width: 75%;
    text-align: right;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    padding: 0.5rem;
  }
}
